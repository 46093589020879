.image-divide-images {
  @include grid-wrap-specific-size(40px);

  &:not(.image-order--one-row) {
    .image-object:nth-child(odd) {
      float: left;
    }
    .image-object:nth-child(even) {
      float: right;
    }
  }
}
.image-object {
  @include grid-item(12);
  @include grid-margin-bottom-size(40px);
  @include grid-padding-left(40px);
}
.image-caption {
  @include font-size(14px);

  font-style: italic;
}

.images-order--two-row {
  .image-object {
    @include grid-item-size(6);
  }
}
