$grid-gutter: 80px;
$grid-gutter-tight: $grid-gutter / 2;

// TODO: Use fraction(e.g 1/4) instead of size(1-12)

@function grid-item-columns-of-12($width) {
  @return $width * (100% / 12);
}

@mixin gutter($side: 'left') {
  @if $side == 'right' {
    padding-right: $grid-gutter;

    @include media-max($breakpoint-grid-tight) {
      padding-right: $grid-gutter-tight;
    }
  } @else {
    padding-left: $grid-gutter;

    @include media-max($breakpoint-grid-tight) {
      padding-left: $grid-gutter-tight;
    }
  }
}

@mixin grid-item-base {
  @include gutter;

  display: inline-block;
  vertical-align: top;
}

@mixin grid-item($width) {
  @include grid-item-base;

  width: grid-item-columns-of-12($width);
}
@mixin grid-item-size($width) {
  width: grid-item-columns-of-12($width);
}

@mixin grid-item-gutter($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  padding-left: $size;

  @include when-grid-tight {
    padding-left: $size-tight;
  }
}

@mixin grid-wrap($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  margin-left: -$size;

  @include media-max($breakpoint-grid-tight) {
    margin-left: -$size-tight;
  }
}
@mixin grid-wrap-size($size) {
  margin-left: -$size;
}
@mixin grid-wrap-specific-size($size) {
  @include grid-wrap-size($size);

  @include when-grid-tight {
    margin-left: -$size / 2;
  }
}

// Media query mixin for tightening the grid
@mixin when-grid-tight {
  @include media-max($breakpoint-grid-tight) {
    @content;
  }
}

/* -------------------- Margin and padding -------------------- */
@mixin grid-gutter-rule(
  $rule,
  $size: $grid-gutter,
  $size-tight: $grid-gutter-tight
) {
  $rules: ();
  @if type-of($rule) == 'list' {
    $rules: join($rules, $rule);
  } @else {
    $rules: append($rules, $rule);
  }

  @each $rule in $rules {
    #{$rule}: $size;
  }
  @include when-grid-tight {
    @each $rule in $rules {
      #{$rule}: $size-tight;
    }
  }
}
// Vertical margin matching the grid gutter size
@mixin grid-margin-top($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  margin-top: $size;

  @include when-grid-tight {
    margin-top: $size-tight;
  }
}
@mixin grid-margin-bottom(
  $size: $grid-gutter,
  $size-tight: $grid-gutter-tight
) {
  margin-bottom: $size;

  @include when-grid-tight {
    margin-bottom: $size-tight;
  }
}
@mixin grid-margin-bottom-size($size) {
  margin-bottom: $size;

  @include when-grid-tight {
    margin-bottom: $size / 2;
  }
}
@mixin grid-padding-left($size) {
  padding-left: $size;

  @include when-grid-tight {
    padding-left: $size / 2;
  }
}
@mixin grid-padding-bottom(
  $size: $grid-gutter,
  $size-tight: $grid-gutter-tight
) {
  @include grid-gutter-rule(padding-bottom, $size, $size-tight);
}
@mixin grid-pull-bottom($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  margin-bottom: -$size;

  @include when-grid-tight {
    margin-bottom: -$size-tight;
  }
}

/* -------------------- Reset -------------------- */
// Reset grid
@mixin grid-wrap-reset {
  margin-left: 0;
}
@mixin grid-item-reset {
  display: block;
  width: auto;
  padding-left: 0;
}
