$wrap-width: rem(800px);
$wrap-width-thin: rem(650px);
$wrap-width-wide: rem(1200px);

$page-margin: rem(50px);
$section-spacing: 14vh;

$logo-height: 60px;
$logo-width: 155px;
$logo-height-small: $logo-height / 1.7;
$logo-width-small: $logo-width / 1.7;

// Form elements
$form-control-font-size: 15px;
$form-control-radius: 6px;
$form-field-padding: rem(5px);
$form-control-height: rem(30px);

// Breakpoints
$breakpoint-full-wrap: rem(1020px);
$breakpoint-menu-toggle: rem(950px);

// Reduce text sizes
$breakpoint-type-medium: rem(1200px);
$breakpoint-type-small: rem(450px);

$breakpoint-grid-tight: rem(800px);
$breakpoint-page-margin-small: rem(550px);

$breakpoint-common-wide: rem(1080px);
