/* -Buttons
-----------------------------------------------------------------------------*/

/*---------- Mixins ----------*/
@mixin button {
  display: inline-flex;
  align-items: center;
  height: rem(50px);
  padding: 0 18px;
  border-radius: $form-control-radius;
  font-family: $font-stack-main;
  font-weight: $font-weight-regular;
  @include font-size($form-control-font-size);
  line-height: rem(50px) - rem(2px);
  text-decoration: none;
  text-align: center;
  text-shadow: none;
  vertical-align: baseline;
  box-shadow: none;
  transition: background-color 0.3s ease-out;

  &:focus {
    outline: 1px dotted $color-body-foreground;
  }
}

@mixin button-primary-colors {
  background-color: $color-action;
  color: #fff;
}
@mixin button-primary {
  @include button;
  @include button-primary-colors;

  &:hover,
  &:focus {
    color: $color-white;
    background-color: lighten($color-action, 15%);
  }
  &[disabled],
  &.disabled {
    background-color: desaturate(lighten($color-action, 20%), 20%);
  }
}

@mixin button-secondary-colors {
  background-color: $color-green-dark;
  color: $color-white;
}
@mixin button-secondary {
  @include button;
  @include button-secondary-colors;

  &:hover,
  &:focus {
    background-color: darken($color-green-dark, 15%);
  }
  &[disabled],
  &.disabled {
    @include button-secondary-colors;
    color: rgba(0, 0, 0, 0.4);
  }
}

/*---------- Standard button classes ----------*/
.btn,
a.btn,
button,
input[type='submit'],
input[type='button'],
input[type='reset'] {
  @include button-primary;

  &[disabled] {
    cursor: default;
  }
  > span {
    vertical-align: middle;
  }
}

a.btn--primary-colors,
.btn--primary-colors {
  @include button-primary-colors;
}

a.btn-secondary,
.btn-secondary {
  @include button-secondary;
}
a.btn--secondary-colors,
.btn--secondary-colors {
  @include button-secondary-colors;
}
