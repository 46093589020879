.inner-footer {
  position: relative;
}
.footer-logo {
  position: absolute;
  top: 30px;
  left: 30px;
  opacity: 0.2;

  img {
    width: 20px;
  }
}

.footer-navigation {
  padding: 0 60px 60px;

  > ul {
    li {
      padding-top: 30px;
      padding-left: 20px;
      padding-right: 20px;
      vertical-align: top;
    }
  }
  .children-menu {
    margin-top: 15px;

    li {
      display: block;
      padding: 0;
    }
    a {
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

@include media-max(rem(800px)) {
  .footer-navigation {
    padding-left: 0;
    padding-right: 0;

    > ul {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding-left: 20px;
      text-align: left;

      li {
        min-width: 50%;
        padding-right: 20px;
        padding-left: 0;
      }
    }
  }
  .footer-logo {
    top: auto;
    bottom: 15px;
    left: 20px;
  }
}
