ul.messages {
  display: block;
  margin: 0;

  li {
    @include font-size(14px);
    font-weight: $font-weight-bold;
    list-style-type: none;
    text-align: center;
    padding: 25px;

    &.debug,
    &.warning {
      background-color: $color-yellow-bright;
    }
    &.error {
      background-color: $color-red-bright;
      color: #fff;
    }
    &.success {
      background-color: $color-gray-light;
    }
  }
}

.site-header {
  position: relative;
  display: table;
  width: 100%;
  padding: 10px 15px;

  ul {
    list-style: none;
    margin: 0;
  }
}

.site-header-logo,
.main-navigation {
  display: table-cell;
  vertical-align: middle;
}

.site-header-logo {
  padding: 10px 0;

  a {
    display: block;
    height: $logo-height;
    width: $logo-width;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

.main-navigation {
  width: 99%;
  text-align: right;

  a:link,
  a:visited {
    @include font-size(14px);

    text-transform: uppercase;
    font-weight: $font-weight-bold;
    color: $color-dark-gray;
    padding: 5px 10px;
    text-decoration: none;
  }
}

.top-navigation,
.nav-toggle,
.split-menu-dropdown {
  display: inline-block;
  vertical-align: middle;
}

.top-navigation {
  > ul > li {
    display: inline-block;
    margin: 7px 10px;
  }
  a {
    border: 2px solid transparent;
    border-radius: 5px;

    &:hover,
    &:focus {
      border-color: $color-gray-light;
    }
  }

  .active {
    a {
      border-color: $color-gray-light;
    }
  }
}

.top-navigation,
.footer-navigation {
  a {
    display: block;
  }
}

.footer-navigation,
.sub-navigation {
  background-color: $color-gray-light;

  > ul {
    > li {
      display: inline-block;
    }
  }
}

.footer-navigation,
.sub-navigation {
  > ul {
    > li {
      padding: 12px 5px;
    }
  }
}

.footer-navigation,
.sub-navigation,
.js .split-menu-dropdown {
  > ul {
    text-align: center;

    ul {
      margin-left: 0;
    }
    > li {
      &.active > a {
        background-color: $color-green;
        color: $color-white;
        border-radius: 6px;
      }
    }
    a {
      @include font-size(14px);

      color: $color-dark-gray;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      text-decoration: none;
      padding: 5px 10px;
      border: 2px solid transparent;
    }
  }

  > ul {
    > li {
      > a {
        background-color: $color-white;
        border-radius: 6px;

        &:hover,
        &:focus {
          background-color: $color-green;
          color: $color-white;
        }
      }
    }
  }
}

.footer-navigation {
  .children-menu {
    .active {
      a {
        text-decoration: underline;
      }
    }
  }
}

.sub-navigation {
  margin-bottom: $section-spacing;
}

/* Error heading padding */
.error {
  #header {
    padding: 20px;
  }
}

// Tighten a bit
@include media-max(rem(1200px)) {
  .top-navigation > ul > li,
  .no-js .split-menu-dropdown li {
    margin-left: 5px;
    margin-right: 5px;
  }
}

/* ------------------- Menu toggle buttons ------------------- */
.nav-toggle {
  min-width: rem(100px);
  height: auto;
  padding: 0 10px;
  border: 0;
  background: transparent !important;
  color: $color-dark-gray !important;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  line-height: $base-line-height;
  text-align: left;

  &::before {
    content: '';
    display: inline-block;
    height: $logo-height;
    width: 25px;
    background: url('../img/menu-hamburger.svg') center no-repeat;
    vertical-align: middle;
    margin-right: 4px;
  }
  &:hover,
  &:focus {
    opacity: 0.6;
  }
}
.nav-main-toggle,
.no-js .split-menu-item {
  display: none !important;
}

/* ------------------- Split menu ------------------- */
@include media-min($breakpoint-menu-toggle + rem(1px)) {
  .js {
    .split-menu-dropdown {
      visibility: hidden;
      position: absolute;
      transform: translateX(100%);
      right: 0;
      top: 100%;
      overflow: hidden;
      margin: 0 !important;
      padding: 5px;
      background-color: $color-gray-light;
      z-index: $z-toggled-menu;

      li {
        margin: 15px;
      }
      a:link,
      a:visited {
        display: block;

        &:hover,
        &:focus {
          background-color: $color-green;
          color: $color-white;
        }
      }
    }
    .split-menu-open ~ .split-menu-dropdown {
      visibility: visible;
      transform: translateX(0);
      transition-delay: 0s;
    }
  }
  // Transitions can be triggered on page load, looks wonky. Wait for JS.
  .js-init .split-menu-dropdown {
    transition: transform 0.2s ease, visibility 0s ease 0.2s;
  }
  .no-js {
    .split-menu-dropdown {
      margin: 0;

      li {
        display: inline-block;
        margin: 7px 10px;
      }
    }
  }
}

/* ------------------- Toggled small screen menu ------------------- */
@include media-max($breakpoint-menu-toggle) {
  .split-menu-item {
    display: none !important;
  }
  .js {
    .nav-main-toggle {
      display: inline-block !important;
    }
    // TODO: Fix duplication of this and split-menu-dropdown
    .nav-main {
      visibility: hidden;
      position: absolute;
      top: 100%;
      right: 0;
      padding: 5px;
      transform: translateX(100%);
      background-color: $color-gray-light;
      z-index: $z-toggled-menu;

      li {
        display: block;
        margin: 15px;
      }
      > li > a {
        text-align: center;
        background-color: $color-white;
        border-radius: 6px;

        &:hover,
        &:focus {
          background-color: $color-green;
          color: $color-white;
        }
      }
      > .active > a {
        background-color: $color-green;
        color: $color-white;
      }
    }
  }
  // Transitions can be triggered on page load, looks wonky. Wait for JS.
  .js-init .nav-main {
    transition: transform 0.2s ease, visibility 0s ease 0.2s;
  }
  .nav-main-open .nav-main {
    visibility: visible;
    transform: translateX(0);
    transition-delay: 0s;
  }
  .split-menu-dropdown {
    margin: 0 !important;
  }
  .site-header-logo {
    a {
      height: $logo-height-small;
      width: $logo-width-small;
    }
  }
}

@include media-max(rem(400px)) {
  .nav-main {
    width: 100%;
  }
  .footer-navigation {
    > ul {
      li {
        width: 100%;
      }
    }
  }
}
