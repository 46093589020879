.staff-blocks--all-info {
  &.staff-blocks {
    @include grid-wrap(60px, 30px);
  }

  & .staff-block {
    @include grid-item(12);
    @include grid-padding-left(60px);

    margin-bottom: 20px;
  }

  &.staff-blocks--cols-2 {
    @include media-min(rem(500px)) {
      .staff-block {
        @include grid-item-size(6);
      }
    }
  }

  &.staff-blocks--cols-3 {
    @include media-min(rem(500px)) {
      .staff-block {
        @include grid-item-size(6);
      }
    }
    @include media-min(rem(800px)) {
      .staff-block {
        @include grid-item-size(4);
      }
    }
  }
  .staff-info-name {
    font-weight: $font-weight-bold;
  }
}

.staff-blocks--simple-info {
  &.staff-blocks {
    @include grid-wrap(60px, 30px);
  }
  .staff-block {
    @include grid-item(3);
    @include grid-padding-left(60px);

    margin-bottom: 20px;
  }
}

.staff-block-text {
  text-align: center;

  h2,
  h3,
  h4 {
    color: $color-dark-gray;
  }
}
.staff-block-link {
  @include font-size(14px);

  margin-top: $page-margin;
  text-align: center;
}
.staff-info {
  @include font-size(18px);

  color: $color-dark-gray;
  padding-top: 15px;
  text-align: center;
}
.staff-info-email {
  word-wrap: break-word;
}
.staff-image {
  text-align: center;

  img {
    border-radius: 170px;
  }
}

// Singe-reference class is added to each house own page.
.single-reference {
  .staff-blocks-wrap--cols-2 {
    max-width: none;
    margin: 0;
  }
  .staff-blocks--all-info {
    &.staff-blocks--cols-2,
    &.staff-blocks--cols-3 {
      @include media-min(rem(500px)) {
        .staff-block {
          @include grid-item-size(6);
        }
      }
      @include media-min(rem(800px)) {
        .staff-block {
          @include grid-item-size(4);
        }
      }
    }
  }
  .staff-block-text,
  .staff-block-link {
    text-align: left;
  }
}

@include media-max($breakpoint-type-small) {
  .staff-blocks--simple-info {
    .staff-info {
      @include font-size(12px);
    }
  }
}
