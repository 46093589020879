/* -'Images' block
-----------------------------------------------------------------------------*/
.images-block-item {
  > a {
    display: block;
  }
}

.images-block-item-caption {
  .images-block--cols-1 &,
  .images-block--cols-2 &,
  .images-block--cols-3 &,
  .images-block--cols-4 &,
  .images-block--gallery & {
    font-size: rem(14px);

    p {
      margin: 0.5em 0 0;
    }
  }
}

.images-block-item-image {
  a {
    transition: opacity 0.15s ease;

    &:hover,
    &:focus {
      opacity: 0.75;
    }
  }
}

/*-------------------- Columns layout --------------------*/
$col-grid-gutter: 50px;
$col-grid-gutter-tight: $col-grid-gutter / 2;

.images-block {
  @include grid-wrap($col-grid-gutter, $col-grid-gutter-tight);
  @include grid-pull-bottom($col-grid-gutter, $col-grid-gutter-tight);

  padding-bottom: $text-spacing;
}

.images-block-item {
  @include grid-item(12);
  @include grid-item-gutter($col-grid-gutter, $col-grid-gutter-tight);
  @include grid-padding-bottom($col-grid-gutter, $col-grid-gutter-tight);
}

@include media-min(rem(400px)) {
  .images-block-item {
    .images-block--cols-2 & {
      @include grid-item-size(6);
    }
    .images-block--cols-3 & {
      @include grid-item-size(4);
    }
    .images-block--cols-4 & {
      @include grid-item-size(6);
    }
  }
}

@include media-min(rem(800px)) {
  .images-block-item {
    .images-block--cols-4 & {
      @include grid-item-size(3);
    }
  }
}

/*-------------------- Gallery layout --------------------*/
$images-block-gallery-gutter: 30px;
.images-block--gallery {
  @include grid-wrap(
    $images-block-gallery-gutter,
    $images-block-gallery-gutter / 2
  );

  .images-block-item {
    @include grid-item(3);
    @include grid-item-gutter(
      $images-block-gallery-gutter,
      $images-block-gallery-gutter / 2
    );

    @include grid-margin-bottom(
      $images-block-gallery-gutter,
      $images-block-gallery-gutter / 2
    );
  }
  .images-block-item:first-child,
  .images-block-item:first-child + .images-block-item {
    @include grid-item-size(6);
  }
  p {
    margin: 0;
  }
}

/*-------------------- Text-to-the-side layout --------------------*/
$text-to-the-side-gutter: 50px;
$text-to-the-side-gutter-tight: $text-to-the-side-gutter / 2;
.images-block--side-text {
  @include grid-margin-top(
    $text-to-the-side-gutter,
    $text-to-the-side-gutter-tight
  );

  .images-block-item {
    @include grid-wrap(
      $text-to-the-side-gutter,
      $text-to-the-side-gutter-tight
    );
    @include grid-margin-bottom(
      $text-to-the-side-gutter,
      $text-to-the-side-gutter-tight
    );

    display: flex;

    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
  .images-block-item-image,
  .images-block-item-caption {
    @include grid-item-gutter(
      $text-to-the-side-gutter,
      $text-to-the-side-gutter-tight
    );
  }
  .images-block-item-image {
    flex-shrink: 0;
    max-width: 50%;
  }

  @include media-max(rem(550px)) {
    margin-bottom: -50px;

    .images-block-item {
      @include grid-wrap-reset;

      display: block;
      margin-bottom: 50px;
    }
    .images-block-item-image,
    .images-block-item-caption {
      @include grid-item-reset;
    }
    .images-block-item-image {
      max-width: none;
    }
    .images-block-item-caption {
      margin-top: 10px;
    }
  }
}
