/* -Link block
---------------------------------------------------------------------------- */
.link-blocks {
  @include grid-wrap(40px, 20px);

  display: flex;
  flex-wrap: wrap;
}

.link-block {
  @include grid-item(4);
  @include grid-item-gutter(40px, 20px);
  @include grid-margin-bottom(40px, 20px);

  display: flex;
  position: relative;

  @include media-max($breakpoint-grid-tight) {
    @include grid-item(6);
    @include grid-item-gutter(40px, 20px);

    display: flex;
  }
  @include media-max($breakpoint-type-small) {
    @include grid-item(12);
    @include grid-item-gutter(40px, 20px);

    display: flex;
    justify-content: center;
  }

  .overlay-link {
    left: 40px;

    @include when-grid-tight {
      left: 20px;
    }
  }
}

.link-block-inner {
  padding: 20px;
  width: 100%;
  max-width: 300px;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  text-align: center;
  color: $color-dark-gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .overlay-link:hover ~ &,
  .overlay-link:focus ~ & {
    border-color: #aaa;
    text-decoration: underline;
  }
}

.link-block-image {
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  img {
    max-height: 100%;
  }
}

.link-block-title {
  margin-bottom: 10px;
  font-size: rem(20px);
  font-family: inherit;
  font-weight: $font-weight-bold;
  font-style: normal;
}

.link-block-url {
  span {
    color: inherit;
    font-size: rem(14px);
  }
}
