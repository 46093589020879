/* Custom style for template images.html from django-common */
.images-block--side-text {
  p {
    font-family: $font-stack-main;
    color: $color-body-foreground;
    font-weight: $font-weight-regular;
    font-style: normal;
    font-size: rem(20px);

    @include media-min($breakpoint-type-small) {
      font-size: rem(24px);
    }
  }
}
