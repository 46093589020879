.module-reference-list-page-list {
  margin-top: 0;
}
.reference-list,
.reference-list-inner {
  @include grid-wrap;
  @include grid-pull-bottom;
}

.reference-block {
  @include grid-item(4);
  @include grid-margin-bottom;
}

.module-reference-list {
  .reference-list {
    @include grid-wrap-specific-size(60px);
  }
  .reference-block {
    @include grid-padding-left(60px);
  }
}

.reference-block-inner {
  position: relative;

  a:hover,
  a:focus {
    color: red !important;
    ~ .reference-text * {
      text-decoration: underline;
    }
  }
}

.reference-list-info {
  display: table;
  width: 100%;
  margin-bottom: 20px;
}
.reference-list-title,
.reference-link {
  @include font-size(14px);

  display: table-cell;
  text-transform: uppercase;
  color: $color-dark-gray;
  font-weight: $font-weight-bold;

  a:link,
  a:visited {
    color: $color-dark-gray;
  }
}
.reference-title {
  @include font-size(20px);

  font-family: $font-stack-alt;
  font-style: italic;
  color: $color-dark-gray;
  padding-top: 10px;
}
.reference-link {
  text-align: right;

  img {
    height: 22px;
    width: 12px;
  }
  a {
    text-decoration: none;
  }
  span {
    padding-left: 10px;
    vertical-align: text-bottom;
  }
}
.reference-info {
  @include font-size(18px);

  padding-top: 10px;

  ul {
    margin: 0;
  }
  li {
    display: inline-block;
    margin-right: 20px;
  }
}
.reference-image {
  img {
    min-height: 100%;
    min-width: 100%;
  }
}

.reference-list-page {
  .main-content {
    margin-top: 40px;
    margin-bottom: 0;
    text-align: center;
  }
  .reference-block {
    @include grid-item(6);
  }
  .reference-title {
    @include font-size(24px);
  }
}

.reference-list-filter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reference-filter-types {
  display: flex;
  margin-bottom: 20px;
}

.reference-filter-floors-rooms-sorting {
  display: flex;
  margin-bottom: 40px;
}

.reference-filter-title {
  @include font-size(18px);

  color: $color-gray;
  padding-right: 10px;
}

.reference-filter-component {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 40px;

  &:last-of-type {
    margin-right: 0;
  }

  input {
    opacity: 0;
    position: absolute;

    &:checked {
      & ~ label {
        color: $color-white;
        background-color: $color-green;
        border-color: $color-green;
      }
    }
    &:focus {
      & ~ label {
        outline: 1px dotted $color-dark-gray;
      }
    }
  }
}
.reference-filter-input {
  display: inline-block;
  margin-right: 10px;

  label {
    @include font-size(14px);

    color: $color-dark-gray;
    padding: 5px 15px;
    border: 2px solid $color-gray-light;
    border-radius: 6px;
    transition: border 150ms ease-out;
    &:hover {
      border-color: $color-green;
    }
  }
}

.reference-sorting-list {
  @include font-size(18px);

  color: $color-dark-gray;
}

.filter-no-hits {
  margin-left: 80px;
  text-align: center;
}

.reference-button-more {
  @include font-size(14px);

  display: block;
  width: 100%;
  height: auto;
  line-height: 1;
  color: $color-dark-gray;
  text-align: center;
  border: 1px solid $color-dark-gray;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  background: transparent;
  margin: 50px 0;
  padding: 15px 10px;

  .spinner {
    display: none;
  }

  &:hover,
  &:focus {
    border-color: $color-green;
  }
}

.reference-list-no-data {
  margin-bottom: 0;

  & ~ p > .reference-button-more {
    display: none;
  }
}

@include media-max($breakpoint-full-wrap) {
  .reference-filter-component {
    margin-right: 0;
  }
}

@include media-max(rem(870px)) {
  .reference-list-filter {
    align-items: flex-start;
  }
  .reference-filter-floors-rooms-sorting {
    flex-direction: column;
  }
  .reference-filter-rooms,
  .reference-filter-floors,
  .reference-filter-types {
    align-items: flex-start;
  }
  .reference-filter-sort {
    margin-left: 0;
  }
  .reference-filter-component {
    margin-bottom: 20px;
  }
  .reference-filter-input {
    margin-bottom: 10px;
  }
}

@include media-max($breakpoint-grid-tight) {
  .reference-list-page .reference-block,
  .reference-block {
    @include grid-item(12);
  }
}
