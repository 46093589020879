// Font stacks
$font-stack-main: 'Source Sans Pro', Arial, sans-serif;
$font-stack-alt: 'Playfair Display', serif;
$font-stack-text: 'Taz', Arial, sans-serif;
$font-stack-mono: 'Consolas', 'Andale Mono', 'Lucida Console', monospace;

// Text style
$base-font-size: 16px;
$body-font-size: 24px;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 600;
$font-weight-extra-bold: 800;

// Paragraph margins and such
$base-text-spacing: 45px;
$text-spacing: rem($base-text-spacing);

// $text-spacing without 'em'
$base-line-height: $text-spacing / 1rem;

// Heading sizes
$h1: 48px;
$h2: 42px;
$h3: 32px;
$h4: 26px;
$h5: $body-font-size;

// Larger and smaller parapgraph text
$lead-text-font-size: 30px;
