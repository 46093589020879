/* -Base
-----------------------------------------------------------------------------*/
html {
  font-size: $base-font-size;
}
body {
  background: $color-body-background;
  color: $color-body-foreground;
  font-size: 1em;
  line-height: 1.5;
  tab-size: 4;
}
p,
body,
button,
input,
optgroup,
select,
textarea {
  font-family: $font-stack-main;
  color: $color-body-foreground;
  font-weight: $font-weight-regular;
}

::selection {
  background: desaturate(lighten($color-main, 30%), 30%);
  color: #fff;
  text-shadow: none;
}

/* -Text element spacing
-----------------------------------------------------------------------------*/
p,
blockquote,
dl,
ol,
ul,
pre,
table {
  margin-bottom: $text-spacing;
}
dl dd,
dl dl {
  margin-left: $text-spacing;
}
b {
  color: $color-black;
  font-weight: inherit;
}
ol,
ul {
  li & {
    margin-bottom: 0;
  }
  nav & {
    margin: 0;
    list-style: none;
    list-style-image: none;
  }
  ol,
  ul {
    margin-bottom: 0;
  }
}
ul {
  list-style: disc outside;
  margin-left: 1em;

  ul {
    list-style-type: square;
    margin-left: $text-spacing;

    ul {
      list-style-type: circle;
    }
  }
}
ol {
  list-style: decimal outside;
  margin-left: 1.5em;

  ol {
    list-style-type: upper-roman;
    margin-left: $text-spacing;

    ol {
      list-style-type: upper-alpha;
    }
  }
}

.running-text {
  > p,
  > ol,
  > ul {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

/* -Text element styles
-----------------------------------------------------------------------------*/
blockquote,
q {
  quotes: none;
}
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}
blockquote {
  position: relative;
  padding-left: 40px;
  border-left: 10px solid;
  font-family: $font-stack-text;
  font-style: italic;

  i {
    font-weight: inherit;
  }
  p,
  ol,
  ul {
    @include font-size(34px);

    margin-top: 0.75em;
    margin-bottom: 0;
    line-height: 1.35;
    font-weight: $font-weight-regular;
    font-style: italic;

    &:first-child {
      margin-top: 0;
    }
  }

  @include media-max(rem(550px)) {
    padding-left: 25px;

    p,
    ol,
    ul {
      @include font-size(24px);
    }
  }
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
cite,
dfn,
q {
  font-style: italic;
}
strong,
dt,
mark,
th {
  font-weight: $font-weight-bold;
}
del {
  color: lighten($color-body-foreground, 20%);
  text-decoration: line-through;
}
ins,
mark {
  background: #ffa;
  color: $color-body-foreground;
  text-decoration: none;
}
pre,
code,
kbd,
samp {
  font-family: $font-stack-mono;
  font-size: rem(14px);
}
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: auto;
}
small {
  font-size: rem(12px);
}
sub,
sup {
  font-size: em(12px);
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
.rich-text a,
.rich-text a:visited,
.rich-text a:link,
a b,
a:visited b,
a:link b {
  color: $color-green;
}

/* -Classes
-----------------------------------------------------------------------------*/
.content {
  @include font-size($body-font-size);

  @include media-max($breakpoint-type-small) {
    @include font-size($body-font-size - 4px);
  }
}

.lead-text {
  @include font-size($lead-text-font-size);

  p,
  ol,
  ul {
    font-weight: $font-weight-bold;
  }

  @include media-max($breakpoint-type-small) {
    @include font-size($lead-text-font-size - 8px);
  }
}

.module {
  margin-top: $section-spacing;
  margin-bottom: $section-spacing;
}

.module-top-image {
  margin-top: 0;
  margin-bottom: 0;
}

%chevron-base {
  content: '';
  display: inline-block;
  height: 25px;
  width: 13px;
  margin-left: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../img/small-arrow.svg');
  vertical-align: middle;

  @include media-max($breakpoint-page-margin-small) {
    height: 16px;
    width: 8px;
    background-size: 8px 16px;
  }
}

.chevron-left {
  &::before {
    @extend %chevron-base;

    transform: rotateZ(180deg);
  }
}
.chevron-right {
  &::after {
    @extend %chevron-base;
  }
}
.chevron-down {
  &::after {
    @extend %chevron-base;

    margin-left: 0;
    transform: rotateZ(90deg);
  }
}
