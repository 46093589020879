a:link,
a:visited {
  color: $color-action;
}
a:hover,
a:focus {
  text-decoration: none;
}

%overlay-link {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  text-indent: -999px;
  font-size: 12px;
  z-index: $z-overlay-link;

  /* A 10x10 transparent PNG; a fix for the link not being clickable in IE */
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABdJREFUeNpi/P//PwMxgHFUIX0VAgQYACK+He3OQHX/AAAAAElFTkSuQmCC') !important;
}
.overlay-link {
  @extend %overlay-link;
}

%base-link {
  text-decoration: none;
  color: $color-action;

  &:hover,
  &:focus {
    color: darken($color-action, 15%);
  }
}

%arrow-link {
  &:link,
  &:visited {
    @include font-size(14px);

    color: $color-dark-gray;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    text-decoration: none;
  }

  &:hover,
  &:focus {
    opacity: 0.8;
  }
  &::after {
    @extend %chevron-base;
  }
}

.arrow-link {
  @extend %arrow-link;
}
