/* -Global bits and pieces
-----------------------------------------------------------------------------*/
html {
  scroll-behavior: smooth;

  @media (prefers-reduced-motion: reduce) {
    scroll-behavior: auto;
  }
}

// Class handled with JavaScript, which tries to take accessibility in mind.
// Sets hide-focus when a mouse is used and show focus when a keyboard is.
// Hide outlines for mouse users and make it more distinct for keyboard users.
a,
button,
input[type='submit'],
input[type='button'],
input[type='reset'],
[tabindex] {
  .hide-focus & {
    outline: 0 !important;
  }
  .show-focus &:focus {
    outline: 3px dotted $color-gray-dark;
    outline-offset: 1px;
  }
  // Override outline colors when necessary
  // .show-focus .something-dark &:focus {
  //   outline-color: #fff;
  // }
}
