.social-media-header {
  margin-bottom: 80px;
  text-align: center;
}
.social-media-blocks {
  @include grid-wrap;
  @include grid-pull-bottom;
}
.social-media-block {
  @include grid-item(6);
  @include grid-margin-bottom;
}
.social-media-header {
  .heading {
    color: $color-dark-gray;
  }
}
.social-media-block-images {
  position: relative;
}
.social-media-link {
  @include font-size(14px);

  margin-top: 30px;
  text-align: center;
  text-transform: uppercase;
  font-weight: $font-weight-bold;

  a:link,
  a:visited {
    display: block;
    color: $color-dark-gray;
    text-decoration: none;
  }
}
.social-media-link-inner {
  display: inline-block;
}
.social-media-logo {
  margin-right: 12px;

  img {
    height: 30px;
    width: 30px;
  }
}

.social-media-large-image,
.social-media-sidebar {
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}
.social-media-large-image {
  img {
    width: 100%;
  }
}
.social-media-dual-image {
  img {
    display: inline-block;
    width: 50%;
  }
}

@include media-max($breakpoint-grid-tight) {
  .social-media-block {
    @include grid-item-size(12);
  }
}
