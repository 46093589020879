.module-image-text-list {
  max-width: rem(1500px);
  padding-left: 0;
  padding-right: 0;

  .module-inner {
    @include grid-wrap;

    margin-left: -4vw;
  }
}
.image-text-column {
  @include grid-item(6);

  padding-left: 4vw;
  margin-bottom: -4vw;

  &.left-column {
    width: 55%;
  }
  &.right-column {
    width: 45%;

    & .rich-text {
      margin-right: 20px;
    }
  }
}
.image-text-column-block {
  margin-bottom: 4vw;
}

@include media-max($breakpoint-grid-tight) {
  .image-text-column {
    &.left-column,
    &.right-column {
      @include grid-item-size(12);

      .rich-text {
        margin: 4vw 20px;
      }
    }
  }
}

@include media-min(rem(1500px)) {
  .module-image-text-list {
    .module-inner {
      margin-left: -50px;
    }
  }
  .image-text-column {
    padding-left: 50px;
    margin-bottom: -50px;
  }
  .image-text-column-block {
    margin-bottom: 50px;
  }
}
