.module-image-block {
  display: flex;
  flex-direction: row;

  &.text-order--right {
    .sidebar-block {
      order: 2;
    }
    .large-image {
      order: 1;
    }
  }
  &.text-order--left {
    .sidebar-block {
      order: 1;
    }
    .large-image {
      order: 2;
    }
  }
  .sidebar-block {
    display: flex;
    flex-direction: column;
    max-width: 500px;
  }
  .image-small-screen {
    display: none;
  }
  .sidebar-text {
    display: inline-flex;
    align-items: center;
    flex-grow: 1;

    .rich-text {
      margin: 50px;
    }
    p {
      margin: 0;
      color: inherit;
    }
    p ~ p {
      margin-top: 0.75em;
    }
  }
  .sidebar-text-inner {
    width: 100%;
    padding: 10px 15px;
    text-align: center;
  }
  .sidebar-image {
    max-height: 280px;
  }
  .large-image {
    img {
      min-height: 100%;
    }
  }
}

@include media-max(1024px) {
  .module-image-block {
    flex-direction: column;

    .sidebar-block {
      max-width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .image-large-screen {
      display: none;
    }
    .image-small-screen {
      display: block;
    }
    .sidebar-image {
      max-height: none;
      max-width: 50%;
    }
    .sidebar-text {
      width: 100%;
      order: -1;

      .rich-text {
        margin: 40px 30px;
      }
    }

    &.text-order--left {
      .large-image {
        order: 1;
      }
      .sidebar-block {
        order: 2;
      }
    }
  }
}

@include media-max(600px) {
  .module-image-block {
    .sidebar-text {
      @include font-size(16px);
    }
  }
}
