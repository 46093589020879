// Color definitions
$color-gray-dark: #222;
$color-gray-middle: #777;
$color-gray-light: #f1f1f1;
$color-blue: #4a90e2;
$color-red: #db001b;
$color-red-bright: #d65b5d;
$color-yellow-bright: #fff446;
$color-green-dark: #28a543;
$color-green-darker: #24963c;
$color-green: #69b964;
$color-green-bright: #8eff5a;
$color-beige: #f1ece6;
$color-beige-darker: #d7d3cd;
$color-white: #fff;
$color-black: #000;

$color-gray-light: #f0f0f0;
$color-gray: #9a9a9a;
$color-dark-gray: #4e4c4d;

// Semantic assignments. When possible and logical, use colors through more
// meaningful, "use case" names.
$color-body-foreground: $color-gray;
$color-body-background: $color-white;
$color-body-secondary-background: $color-beige;
$color-header-background: $color-green-dark;

// Disabled text, lightest accessible text color on white
$color-disabled: #767676;

// Brand things like selection color
$color-main: $color-blue;

// Links and main buttons
$color-action: $color-green;
