@import '../../node_modules/@glidejs/glide/src/assets/sass/glide.core';

.reference-menu-bar {
  @include font-size(14px);

  position: relative;
  display: table;
  width: 100%;
  padding: 0 10px;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  background-color: $color-gray-light;
  z-index: $z-reference-menu;

  ul {
    display: table-cell;
    vertical-align: middle;
  }

  a:link,
  a:visited {
    color: $color-dark-gray;
    text-decoration: none;
  }

  ul {
    text-align: center;
    margin-bottom: 0;
    width: 99%;

    li {
      display: inline-block;
      padding: 15px 5px;

      a {
        padding: 6px 10px;
        background-color: $color-white;
        border-radius: 6px;

        &:hover,
        &:focus {
          background-color: $color-green;
          color: $color-white;
          text-decoration: none;
        }
      }
    }
  }

  &.fixed-nav {
    position: fixed;
    top: 0;

    @media screen and (orientation: landscape) and (max-height: 400px) {
      position: relative;
    }
  }
}

.reference-intro {
  position: relative;
  z-index: $z-reference-intro;

  .slide-image {
    width: 100%;
  }
}

.reference-top-info {
  color: $color-dark-gray;
  background-color: $color-white;
  text-align: center;
  padding: 40px 0;

  h1 {
    margin-bottom: 0;
  }
  ul {
    margin: 0;
  }
}
.reference-size-info {
  @include font-size(18px);

  li {
    display: inline-block;
    margin: 20px 15px 0;
  }
}

.reference-slider {
  // For the alt text when images are loading
  font-size: rem(14px);

  ul,
  li {
    margin: 0;
  }
  .glide {
    &.first-slide {
      .arrow-left {
        display: none;
        visibility: hidden;
      }
    }
    &.last-slide {
      .arrow-right {
        display: none;
        visibility: hidden;
      }
    }
  }
}

.reference-slide {
  img {
    width: 100%;
  }
}

.reference-slider-arrow {
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: $z-index-2;

  button {
    position: absolute;
    height: auto;
    width: auto;
    padding: 8px;
    background-color: $color-white;
    border-radius: 30px;
    border: 0;

    &:hover,
    &:focus {
      background-color: $color-white;
      opacity: 0.6;
    }
  }
  &.arrow-right,
  &.arrow-left {
    button {
      &::before,
      &::after {
        height: 25px;
        width: 25px;
        background-position: center;
        margin-left: 0;

        @include media-max($breakpoint-page-margin-small) {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
  &.arrow-right {
    button {
      right: 15px;
    }
  }
  &.arrow-left {
    button {
      left: 15px;
    }
  }
}
.slider-mini-images {
  width: 100%;
  padding: 20px 5px 0;
  overflow-x: auto;
  text-align: center;

  button {
    position: relative;
    height: auto;
    border: 0;
    padding: 0;
    margin: 0 10px 20px;
    border-radius: 0;
    background: transparent !important;
    vertical-align: top;
  }
  img {
    max-width: 60px;
  }

  .glide__bullet--active {
    &::after {
      content: '';
      display: block;
      position: absolute;
      background-color: #333;
      height: 6px;
      width: 6px;
      border-radius: 3px;
      left: 50%;
      right: 0;
      top: -9px;
      transform: translateX(-50%);
    }
  }

  @include media-max($breakpoint-grid-tight) {
    button {
      margin: 0 5px 15px;
    }
    img {
      max-width: 50px;
    }
  }
}

.reference-facts {
  table {
    border-collapse: collapse;
    width: 100%;
  }
  thead {
    text-align: left;
  }
  tbody {
    tr {
      @include font-size(30px);

      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      font-weight: $font-weight-bold;
    }
  }
  th {
    text-align: left;
  }
  td {
    padding: 5px 0;
    width: 33.333%;

    &.fact-value {
      color: $color-dark-gray;
    }
    &.fact-search {
      @include font-size(18px);

      text-align: right;

      a {
        display: block;
      }
    }
    .fact-show-all {
      display: none;
      font-weight: $font-weight-regular;
      padding-right: 20px;
    }
    a {
      color: $color-gray;
      text-decoration: none;
    }
  }
}

.fact-search,
.fact-search a {
  &:hover,
  &:focus {
    .fact-show-all {
      display: inline-block;
    }
  }
}

.article-file {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-bottom: 50px;

  a:hover,
  a:focus {
    ~ .file-text {
      text-decoration: underline;
    }
  }
}
.file-image {
  border: 5px solid $color-white;
  box-shadow: -3px 3px 10px 0 rgba(0, 0, 0, 0.1);
  margin-right: 60px;
  img {
    max-width: 80px;
  }
}
.file-image,
.file-text {
  display: inline-block;
}
.file-heading {
  font-weight: $font-weight-bold;

  @include media-min($breakpoint-type-medium) {
    @include font-size(30px);
  }
}
.file-description {
  @include font-size(18px);
}

@include media-max(rem(650px)) {
  .reference-menu-bar,
  .reference-slider-arrow {
    display: none;
    visibility: hidden;
  }
  .reference-top-info {
    padding: 15px 0 25px;
  }
  .reference-size-info {
    li {
      margin: 10px 8px 0;
    }
  }
}

@include media-max(rem(550px)) {
  .reference-top-info {
    padding: 5px 0 15px;
  }
  .reference-size-info {
    @include font-size(14px);
  }
}

@include media-max($breakpoint-grid-tight) {
  .reference-facts {
    td,
    th {
      @include font-size(18px);
    }
  }
  .file-image {
    margin-right: 30px;

    img {
      max-width: 60px;
    }
  }
}
