.standard-page,
.start-page {
  .top-image-inner {
    // A column flex items with alignment (.image-main) must be wrapped in a
    // column flex item in IE11.
    display: flex;
    flex-direction: column;

    &,
    p,
    .heading {
      color: #fff;
    }
    p {
      font-weight: $font-weight-bold;
    }
    p,
    .heading {
      text-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    }
    text-align: center;
  }
  .image-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 550px;
    margin: 0 auto;
    background-position: center center;
    background-size: cover;
  }
}
.start-page {
  .image-main {
    min-height: calc(100vh - 100px);
  }

  @include media-min($breakpoint-type-medium) {
    h1 {
      @include font-size(64px);
    }
  }
}

.top-image-text {
  padding: 0 20px 10vh;
}

.top-image-arrow {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;

  .top-image-arrow-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    margin: 0 auto;
    border-radius: 20px;
    background-color: #fff;
    animation-duration: 0.8s;
    animation-name: bump;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    &::after {
      // Doesn't look balanced when properly centered
      position: relative;
      top: 1px;
      background-position: center;
    }
  }
  img {
    background: transparent;
  }
}

@keyframes bump {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-5px);
  }
}

@include media-max($breakpoint-menu-toggle) {
  .start-page {
    .image-main {
      min-height: calc(100vh - 80px);
    }
  }
  .standard-page {
    .image-main {
      min-height: 350px;
    }
  }
}
